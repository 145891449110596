


















































import { Component, PropSync, Vue } from 'vue-property-decorator';

import KuspitAccountLinkingWrongCredentialsViewModel
  from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-wrong-credentials-view-model';

@Component({ name: 'KuspitAccountLinkingWrongCredentials' })
export default class KuspitAccountLinkingWrongCredentials extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  wrong_credentials_view_model = Vue.observable(
    new KuspitAccountLinkingWrongCredentialsViewModel(),
  );
}
